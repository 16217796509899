




















































import { Vue, Component } from 'vue-property-decorator';
import monitorDetails from './monitordetails/index.vue';
import { searchInfoByApiKeySvc, getApiLogListSvc } from '@/services/systemApi';
import spinCard from '@/components/SpinCard/index.vue';
import companyMonitor from './companyMonitor/index.vue';

@Component({
  components: {
    monitorDetails,
    spinCard,
    companyMonitor,
  },
})

// TODO  Agent 区域数据需处理，类型需合适定义;
export default class Monitor extends Vue {
  modal1 = false;
  spinShow = false;
  searchContent = '';
  model1 = '企业key';
  value = '';
  showDetails = false;
  companyId = '';
  companyKey = '';
  companyNum = 0;
  requestLog: any = [];
  tableValue: any = [];
  monitorList = [
    {
      value: '企业key',
      label: '企业key',
    },
  ];
  modalShow(): void {
    this.modal1 = false;
    // this.value = '';
  }
  returnHomePage(val: boolean): void {
    this.showDetails = val;
    this.value = '';
  }
  async mounted(): Promise<void> {
    this.showDetails = false;
    const res = await getApiLogListSvc({});
    if (!res.status) {
      if (Object.values(res.data) && Object.values(res.data).length) {
        this.tableValue = Object.values(res.data);
      }
    }
  }
  async searchContentByKey(): Promise<void> {
    this.spinShow = true;
    this.companyNum = 0;
    const key = 'user_key';
    const res = await searchInfoByApiKeySvc({ [key]: this.value });
    this.searchContent = '';
    if (this.value) {
      if (res.status === 0 || res.status === 2) {
        if ((res as any).data && res.data.length) {
          this.showDetails = true;
          this.companyId = res.data[0].services_id;
          this.companyKey = res.data[0].user_key;
          this.requestLog = [];
          res.data.forEach((i: any) => {
            this.requestLog.push(...i.request);
            this.companyNum += i.count;
          });
        } else {
          this.modal1 = true;
          this.searchContent = '暂无相关信息';
        }
        this.spinShow = false;
      } else {
        this.modal1 = true;
        this.searchContent = res.info;
        this.spinShow = false;
      }
    } else {
      this.spinShow = false;
      this.modal1 = true;
      this.searchContent = '请输入企业KEY查询';
    }
  }
}
