

































































import { Vue, Component } from 'vue-property-decorator';
import monitorDetails from '../monitordetails/index.vue';
import { getApiLogSvc, getApiLogListSvc } from '@/services/systemApi';
import spinCard from '@/components/SpinCard/index.vue';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Component({
  components: {
    monitorDetails,
    spinCard,
  },
})
export default class CompanyMonitor extends Vue {
  modal = false;
  spinShow = false;
  tableValue: any = [];
  requestLog: any = [];
  companyDate = '';
  companyId = '';
  companyKey = '';
  companyNum = 0;
  showDetailContent = false;
  tips = '';
  value = '';
  loading = true;
  total = 0;
  current = 1;
  data: any = [];
  model = '0';
  showDate = true;
  columns = [
    {
      title: '企业id',
      key: 'services_id',
      align: 'center',
    },
    {
      title: '企业服务名称',
      key: 'service_name',
      align: 'center',
    },
    {
      title: '企业密钥',
      key: 'user_key',
      align: 'center',
    },
    {
      title: '密钥请求日期',
      key: 'date_time',
      align: 'center',
    },
    {
      title: '今日总请求次数',
      key: 'count',
      align: 'center',
    },
    {
      title: '密钥请求详细信息',
      key: 'request',
      align: 'center',
      slot: 'request',
    },
  ];
  list = [
    {
      value: '0',
      label: '请选择',
    },
    {
      value: '1',
      label: '企业id',
    },
    {
      value: '2',
      label: '企业服务名称',
    },
    {
      value: '3',
      label: '企业密钥',
    },
    {
      value: '4',
      label: '密钥请求日期',
    },
  ];
  async mounted(): Promise<void> {
    await this.getApiLog(this.current);
  }
  async getApiLog(page: number): Promise<void> {
    const res = await getApiLogSvc({
      page,
      rows: 10,
      search_type: parseInt(this.model, 10),
      search_str: this.value,
    });
    if (res.status === 0 || res.status === 2) {
      this.total = Number((res as any).total);
      this.data = (res as any).data;
    }
    this.loading = false;
    return res;
  }
  pageNumChange(val: number): void {
    this.loading = true;
    this.current = val;
    this.getApiLog(this.current);
  }
  reset(): void {
    this.total = 0;
    this.loading = true;
    this.model = '0';
    this.value = '';
    this.current = 1;
    this.getApiLog(this.current);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  searchContentByList(e: any): void {
    if ((e.type === 'keyup' && e.keyCode === 13) || e.type === 'click') {
      if (this.model !== '0') {
        if (this.value) {
          this.getApiLog(this.current);
        } else {
          this.modal = true;
          this.tips = '请输入查询内容';
        }
      } else {
        this.modal = true;
        this.tips = '请选择查询类型';
      }
    }
  }
  async checkDetails(val: any): Promise<void> {
    this.spinShow = true;
    const res = await getApiLogListSvc({});
    if (!res.status) {
      if (Object.values(res.data) && Object.values(res.data).length) {
        this.tableValue = Object.values(res.data);
      }
    }
    this.requestLog = [];
    if ((val as any).request && val.request.length) {
      val.request.forEach((i: any) => {
        this.requestLog.push(i);
      });
    }
    this.companyDate = val.date_time;
    this.companyKey = val.user_key;
    this.companyNum = val.count;
    this.companyId = val.services_id;
    this.showDetailContent = true;
    this.spinShow = false;
  }
  returnHomePage(val: boolean): void {
    this.showDetailContent = val;
    this.value = '';
  }
  modalShow(): void {
    this.modal = false;
  }
}
