var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-content"},[_c('Table',{attrs:{"border":"","height":"900","columns":_vm.columns,"data":_vm.data},scopedSlots:_vm._u([{key:"result",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(!row.result.status ? '成功: 获取到' + row.result.data_count + '条数据' : '失败: ' + row.result.info)+" ")]}},{key:"user",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.ip)+" ")]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.time ? row.time : 322220)+" ")]}},{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.show(row)}}},[_vm._v("查看")])]}}])}),_c('Modal',{attrs:{"title":"接口返回JSON"},model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('p',[_vm._v(_vm._s(_vm.searchContent))]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.modalShow}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }