





























import { Vue, Component, Prop } from 'vue-property-decorator';
import detailContent from '../detailContent/index.vue';
import { deepClone } from '@/utils';

@Component({
  components: {
    detailContent,
  },
})
export default class DetailContent extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  detailContent!: [];
  data: any = [];
  searchContent = '';
  modal1 = false;
  columns = [
    {
      type: 'index',
      title: '编号',
      align: 'center',
    },
    {
      title: '接口名称',
      key: 'name',
      align: 'center',
    },
    {
      title: '请求时间',
      key: 'date',
      align: 'center',
    },
    {
      title: '请求结果',
      key: 'result',
      align: 'center',
      slot: 'result',
    },
    {
      title: '请求ip地址',
      key: 'user',
      align: 'center',
      slot: 'user',
    },
    {
      title: '接口处理时间(ms)',
      key: 'time',
      align: 'center',
      slot: 'time',
    },
    {
      title: '接口返回json',
      key: 'detail',
      align: 'center',
      slot: 'detail',
    },
  ];
  mounted(): void {
    this.data = deepClone(this.detailContent);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  show(row: any): void {
    this.modal1 = true;
    this.searchContent = row;
  }
  modalShow(): void {
    this.modal1 = false;
  }
}
