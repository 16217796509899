





































import { Vue, Component, Prop } from 'vue-property-decorator';
import detailContent from '../detailContent/index.vue';
import { deepClone } from '@/utils/index';
import spinCard from '@/components/SpinCard/index.vue';
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Component({
  components: {
    detailContent,
    spinCard,
  },
})
export default class MonitorDetails extends Vue {
  spinShow = false;
  @Prop({
    type: Number,
    required: false,
    default: '',
  })
  companyId!: '';
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  companyDate!: '';
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  companyNum!: number;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  showDate!: false;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  companyKey!: '';
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  data1!: [];
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  requestLog!: [];
  columns1 = [
    {
      title: '接口id',
      key: 'id',
      align: 'center',
    },
    {
      title: '接口名称',
      key: 'name',
      align: 'center',
    },
    {
      title: '接口请求次数',
      key: 'num',
      align: 'center',
    },
    {
      title: '请求结果反馈',
      key: 'result',
      align: 'center',
    },
    {
      title: '接口详细分析',
      key: 'analysis',
      align: 'center',
      slot: 'analysis',
    },
  ];
  detailContent: any = [];
  showDetailContent = false;
  data: any = [];
  mounted(): void {
    this.showDetailContent = false;
    this.data = [];
    this.data = this.data1;
    this.data.forEach((j: any) => {
      j.num = 0;
      j.failNum = 0;
      j.successNum = 0;
      j.total = 0;
      j.detail = [];
      j.result = `成功:${j.successNum}次,共获取到${j.total}条数据,失败:${j.failNum}次`;
    });
    if (this.requestLog && (this.requestLog as any).length) {
      this.requestLog.forEach((i: any) => {
        this.data.forEach((j: any) => {
          if (j.id === i.interface_id) {
            (j as any).num += 1;
            i.name = j.name;
            j.time = i.time ? i.time : 0;
            i.result.status ? (j.failNum += 1) : (j.successNum += 1);
            j.total += Number(i.result.data_count);
            j.detail.push(i);
            j.result = `成功:${j.successNum}次,共获取到${j.total}条数据,失败:${j.failNum}次`;
          }
        });
      });
    }
  }
  checkDetails(param: any): void {
    this.detailContent = param.detail;
    this.showDetailContent = true;
  }
  returnPreviousPage(): void {
    if (!this.showDetailContent) {
      this.$emit('returnHomePage', false);
    } else {
      this.showDetailContent = false;
    }
  }
  defineProperty(obj: any, key: any, val: any): any {
    // 返回object对象
    const newObj = deepClone(obj);
    return Object.defineProperty(newObj, key, {
      value: val,
      writable: true,
      configurable: true,
      enumerable: true,
    });
  }
}
